// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "sass:map";
// Plus imports for other components in your app.
@import "./variables";
@import "./typography";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($verify-theme);

/* You can add global styles to this file, and also import other style files */
:root {
  --mat-dialog-actions-padding: 16px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $body-background-color;
  color: #404040;
}

.container {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
}
